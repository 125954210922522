<template>
  <div class="crane">
    <div class="row">
      <!-- 设备在线率 -->
      <div class="r1-c1">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">设备在线率</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-2 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div class="r1-c1-content-wrap" @click="onLookItem">
                <div class="r1-c1-content-row1">
                  <div class="r1-c1-content-row1-item">
                    设备总数
                    <div class="num">{{ deviceOnlineData.total }}台</div>
                  </div>
                  <div class="r1-c1-content-row1-item">
                    使用数量
                    <div class="num">{{ deviceOnlineData.online }}台</div>
                  </div>
                  <div class="r1-c1-content-row1-item">
                    停用数量
                    <div class="num">{{ deviceOnlineData.offline }}台</div>
                  </div>
                </div>
                <div class="r1-c1-content-row2">设备在线率</div>
                <div class="r1-c1-content-row3">
                  <div class="max">
                    <div
                      class="val"
                      :style="{ width: deviceOnlinePercent + '%' }"
                    ></div>
                  </div>
                  <div class="percent">{{ deviceOnlinePercent }}%</div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
      <!-- 司机认证信息 -->
      <div class="r1-c2">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">司机认证信息</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-2 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div class="r1-c2-content-wrap">
                <div class="dirver-list-wrap">
                  <div class="dirver-list-title">
                    <div class="dirver-list-title-item">设备名称</div>
                    <div class="dirver-list-title-item">司机名称</div>
                    <div class="dirver-list-title-item">是否认证</div>
                  </div>
                  <div class="driver-list-data">
                    <div
                      class="dirver-list-row"
                      v-for="(item, index) in deviceDriverData"
                      :key="index"
                    >
                      <div class="dirver-list-row-item">
                        {{ item.deviceName }}
                      </div>
                      <div class="dirver-list-row-item">
                        {{ item.driverName }}
                      </div>
                      <div class="dirver-list-row-item">
                        <img
                          class="dirver-list-row-item-icon"
                          v-if="item.isAuth == 1"
                          src="../assets/device/device-auth.png"
                        />
                        <img
                          class="dirver-list-row-item-icon"
                          v-if="item.isAuth != 1"
                          src="../assets/device/device-disauth.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
      <!-- 种类报警信息 -->
      <div class="r1-c3">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">种类报警信息</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-2 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div class="echarts-crane-r1-c3-wrap">
                <div class="menu">
                  <div
                    v-for="(item, index) in echartsCurveR1C3MenuList"
                    :key="index"
                    class="item"
                    :class="[
                      item.name == echartsCurveR1C3MenuListActive.name
                        ? 'item-active'
                        : '',
                    ]"
                    @click="onToggleEchartsCurveR1C3MenuListActive(item)"
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div id="echarts-crane-r1-c3"></div>
              </div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- 设备载重数据 -->
      <div class="r2-c1">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">设备载重数据</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-2 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div class="echarts-crane-r2-c1-wrap">
                <div class="menu">
                  <div
                    v-for="(item, index) in echartsCurveR2C1MenuList"
                    :key="index"
                    class="item"
                    :class="[
                      item.name == echartsCurveR2C1MenuListActive.name
                        ? 'item-active'
                        : '',
                    ]"
                    @click="onToggleEchartsCurveR2C1MenuListActive(item)"
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div id="echarts-crane-r2-c1"></div>
              </div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
      <!-- 设备报警信息 -->
      <div class="r2-c2">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">设备报警信息</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-2 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div class="echarts-crane-r2-c2-wrap">
                <div class="menu">
                  <div
                    v-for="(item, index) in echartsCurveR2C2MenuList"
                    :key="index"
                    class="item"
                    :class="[
                      item.name == echartsCurveR2C2MenuListActive.name
                        ? 'item-active'
                        : '',
                    ]"
                    @click="onToggleEchartsCurveR2C2MenuListActive(item)"
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div id="echarts-crane-r2-c2"></div>
              </div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Crane",
  components: {},
  data() {
    return {
      deviceOnlineData: {
        total: 0,
        online: 0,
        offline: 0,
      },
      deviceDriverData: [],
      deviceAlarmTypeData: [],
      deviceCurveData: {
        time: [],
        series: [],
      },
      deviceAlarmTotalData: [],
      echarts: {
        echartsCraneR1C3: null,
        echartsCraneR2C1: null,
        echartsCraneR2C2: null,
      },
      echartsCurveR1C3MenuList: [
        {
          name: "今日",
          value: "4",
        },
        {
          name: "一周",
          value: "3",
        },
        {
          name: "一月",
          value: "2",
        },
        // {
        //   name: "一年",
        //   value: "1",
        // },
      ],
      echartsCurveR1C3MenuListActive: {
        name: "一月",
        value: "2",
      },
      echartsCurveR2C1MenuList: [
        {
          name: "今日",
          value: "4",
        },
        {
          name: "一周",
          value: "3",
        },
        {
          name: "一月",
          value: "2",
        },
        // {
        //   name: "一年",
        //   value: "1",
        // },
      ],
      echartsCurveR2C1MenuListActive: {
        name: "一月",
        value: "2",
      },
      echartsCurveR2C2MenuList: [
        {
          name: "今日",
          value: "4",
        },
        {
          name: "一周",
          value: "3",
        },
        {
          name: "一月",
          value: "2",
        },
        // {
        //   name: "一年",
        //   value: "1",
        // },
      ],
      echartsCurveR2C2MenuListActive: {
        name: "一月",
        value: "2",
      },
    };
  },
  computed: {
    projectList: function () {
      return this.$store.state.projectList;
    },
    currentProject: function () {
      return this.$store.state.currentProject;
    },
    deviceOnlinePercent: function () {
      if (this.deviceOnlineData.total && this.deviceOnlineData.online) {
        return Math.floor(
          (this.deviceOnlineData.online / this.deviceOnlineData.total).toFixed(2) * 100
        );
      } else {
        return 0;
      }
    },
  },
  watch: {
    // 监听项目切换
    currentProject(newVal, oldVal) {
      // this.$router.push({ name: "Crane" }); // 返回上级页面
      this.onGetDeviceOnlineData(); // 获取设备在线率数据
      this.onGetDeviceDriverData(); // 获取司机认证信息数据
      this.onGetdeviceAlarmTypeData(); // 获取种类报警信息
      this.onGetDeviceCurveData(); // 获取设备载重数据
      this.onGetDeviceAlarmTotalData(); // 获取设备报警信息
    },
    echartsCurveR1C3MenuListActive: {
      handler: function (newVal, oldVal) {
        this.onGetdeviceAlarmTypeData()
      },
      deep: true
    },
    echartsCurveR2C1MenuListActive: {
      handler: function (newVal, oldVal) {
        this.onGetDeviceCurveData()
      },
      deep: true
    },
    echartsCurveR2C2MenuListActive: {
      handler: function (newVal, oldVal) {
        this.onGetDeviceAlarmTotalData()
      },
      deep: true
    },
  },
  mounted() {
    this.onGetDeviceOnlineData(); // 获取设备在线率数据
    this.onGetDeviceDriverData(); // 获取司机认证信息数据
    this.onGetdeviceAlarmTypeData(); // 获取种类报警信息
    this.onGetDeviceCurveData(); // 获取设备载重数据
    this.onGetDeviceAlarmTotalData(); // 获取设备报警信息
    // this.echartsCraneR1C3Init(); // 种类报警信息饼图
    // this.echartsCraneR2C1Init(); // 设备载重数据曲线图
    // this.echartsCraneR2C2Init(); // 设备报警次数饼图
  },
  methods: {
    // 查看二级页面
    onLookItem: function () {
      this.$router.push({ name: "CraneItem" });
    },
    // 种类报警信息饼图
    echartsCraneR1C3Init: function () {
      this.echarts.echartsCraneR1C3 = this.$echarts.init(
        document.getElementById("echarts-crane-r1-c3")
      ).dispose();
      this.echarts.echartsCraneR1C3 = this.$echarts.init(
        document.getElementById("echarts-crane-r1-c3")
      );
      var listData = this.deviceAlarmTypeData;
      var data = [];
      var legend = [];
      listData.forEach((item, index) => {
        data.push({
          name: item.alarmName,
          value: item.alarmCount,
        });
        legend.push(item.alarmName);
      });
      var option = {
        legend: {
          data: legend,
          orient: "vertical",
          bottom: 10,
          right: 10,
          textStyle: {
            color: "#00e4ff",
          },
          itemWidth: 7,
          itemHeight: 7,
          formatter: function (name) {
            var arr;
            listData.forEach((item, index) => {
              if (name == item.alarmName) {
                arr =
                  item.alarmName +
                  item.alarmCount +
                  "次 " +
                  item.alarmPercent +
                  "%";
              }
            });
            return arr;
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
        },
        series: [
          {
            name: "种类报警信息",
            type: "pie",
            center: ["35%", "50%"],
            radius: ["40%", "70%"],
            label: {
              formatter: "{d}%",
              color: "#00e4ff",
            },
            emphasis: {
              label: {
                show: true,
              },
            },
            data: data,
          },
        ],
      };
      this.echarts.echartsCraneR1C3.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.echartsCraneR1C3.resize();
      });
    },
    // 设备载重数据曲线图
    echartsCraneR2C1Init: function () {
      this.echarts.echartsCraneR2C1 = this.$echarts.init(
        document.getElementById("echarts-crane-r2-c1")
      ).dispose();
      this.echarts.echartsCraneR2C1 = this.$echarts.init(
        document.getElementById("echarts-crane-r2-c1")
      );
      var colorList = [
        {
          itemColor: "rgba(0,179,244,1)",
          shadowColor1: "rgba(0,179,244,0.8)",
          shadowColor2: "rgba(0,179,244,0.3)",
        },
        {
          itemColor: "rgba(0,202,149,1)",
          shadowColor1: "rgba(0,202,149,0.8)",
          shadowColor2: "rgba(0,202,149,0.3)",
        },
        {
          itemColor: "rgba(255,215,0,1)",
          shadowColor1: "rgba(255,215,0,0.8)",
          shadowColor2: "rgba(255,215,0,0.3)",
        },
        {
          itemColor: "rgba(255,69,0,1)",
          shadowColor1: "rgba(255,69,0,0.8)",
          shadowColor2: "rgba(255,69,0,0.3)",
        },
        {
          itemColor: "rgba(160,32,240,1)",
          shadowColor1: "rgba(160,32,240,0.8)",
          shadowColor2: "rgba(160,32,240,0.3)",
        },
        {
          itemColor: "rgba(10,32,240,1)",
          shadowColor1: "rgba(10,32,240,0.8)",
          shadowColor2: "rgba(10,32,240,0.3)",
        },
        {
          itemColor: "rgba(70,12,140,1)",
          shadowColor1: "rgba(70,52,140,0.8)",
          shadowColor2: "rgba(70,52,140,0.3)",
        },
        {
          itemColor: "rgba(155,69,0,1)",
          shadowColor1: "rgba(155,69,0,0.8)",
          shadowColor2: "rgba(155,69,0,0.3)",
        },
        {
          itemColor: "rgba(255,115,0,1)",
          shadowColor1: "rgba(255,115,0,0.8)",
          shadowColor2: "rgba(255,115,0,0.3)",
        },
        {
          itemColor: "rgba(0,202,49,1)",
          shadowColor1: "rgba(0,202,49,0.8)",
          shadowColor2: "rgba(0,202,49,0.3)",
        },
      ];
      var series = [];
      var legend = [];
      this.deviceCurveData.series.forEach((item, index) => {
        var seriesItem = {
          name: item.name,
          type: "line",
          smooth: true, //是否平滑
          showAllSymbol: true,
          symbol: "circle",
          symbolSize: 15,
          lineStyle: {
            normal: {
              color: colorList[index].itemColor,
              shadowColor: "rgba(0, 0, 0, .3)",
              shadowBlur: 0,
              shadowOffsetY: 5,
              shadowOffsetX: 5,
            },
          },
          label: {
            show: true,
            position: "top",
            textStyle: {
              color: colorList[index].itemColor,
            },
          },
          itemStyle: {
            opacity: 0,
            // borderColor: "#fff",
            // borderWidth: 3,
            // shadowColor: "rgba(0, 0, 0, .3)",
            // shadowBlur: 0,
            // shadowOffsetY: 2,
            // shadowOffsetX: 2,
          },
          tooltip: {
            show: true,
          },
          areaStyle: {
            normal: {
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: colorList[index].shadowColor2,
                  },
                  {
                    offset: 1,
                    color: "rgba(0,179,244,0)",
                  },
                ],
                false
              ),
              shadowColor: colorList[index].shadowColor1,
              shadowBlur: 20,
            },
          },
          data: this.deviceCurveData.series[index].data,
        };
        series.push(seriesItem);
        legend.push(item.name);
      });
      var option = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
          axisPointer: {
            lineStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(0, 255, 233,0)",
                  },
                  {
                    offset: 0.5,
                    color: "rgba(255, 255, 255,1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(0, 255, 233,0)",
                  },
                ],
                global: false,
              },
            },
          },
        },
        legend: {
          data: legend,
          top: 10,
          right: "5%",
          textStyle: {
            color: "#00e4ff",
          },
        },
        grid: {
          top: "15%",
          left: "5%",
          right: "5%",
          bottom: "15%",
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              show: true,
            },
            axisLabel: {
              color: "#00e4ff",
            },
            splitLine: {
              show: false,
            },
            boundaryGap: false,
            data: this.deviceCurveData.time,
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            // max: 140,
            splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,0.1)",
              },
            },
            axisLine: {
              show: false, // Y 轴的竖线
            },
            axisLabel: {
              show: false, // Y 轴的参考单位
              margin: 20,
              textStyle: {
                color: "#d1e6eb",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: series,
      };
      this.echarts.echartsCraneR2C1.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.echartsCraneR2C1.resize();
      });
    },
    // 设备报警次数饼图
    echartsCraneR2C2Init: function () {
      this.echarts.echartsCraneR2C2 = this.$echarts.init(
        document.getElementById("echarts-crane-r2-c2")
      ).dispose();
      this.echarts.echartsCraneR2C2 = this.$echarts.init(
        document.getElementById("echarts-crane-r2-c2")
      );
      var listData = this.deviceAlarmTotalData;
      var data = [];
      var legend = [];
      listData.forEach((item, index) => {
        data.push({
          name: item.alarmName,
          value: item.alarmCount,
        });
        legend.push(item.alarmName);
      });
      var option = {
        legend: {
          data: legend,
          orient: "vertical",
          bottom: 10,
          right: 10,
          textStyle: {
            color: "#00e4ff",
          },
          itemWidth: 7,
          itemHeight: 7,
          formatter: function (name) {
            var arr;
            listData.forEach((item, index) => {
              if (name == item.alarmName) {
                arr =
                  item.alarmName +
                  item.alarmCount +
                  "次 " +
                  item.alarmPercent +
                  "%";
              }
            });
            return arr;
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
        },
        series: [
          {
            name: "设备报警信息",
            type: "pie",
            center: ["35%", "50%"],
            roseType: "radius",
            itemStyle: {
              borderRadius: 5,
            },
            label: {
              formatter: "{d}%",
              color: "#00e4ff",
            },
            emphasis: {
              label: {
                show: true,
              },
            },
            data: data,
          },
        ],
      };
      this.echarts.echartsCraneR2C2.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.echartsCraneR2C2.resize();
      });
    },
    // 切换R1C3时间范围
    onToggleEchartsCurveR1C3MenuListActive: function (item) {
      this.echartsCurveR1C3MenuListActive = item;
    },
    // 切换R2C1时间范围
    onToggleEchartsCurveR2C1MenuListActive: function (item) {
      this.echartsCurveR2C1MenuListActive = item;
    },
    // 切换R2C2时间范围
    onToggleEchartsCurveR2C2MenuListActive: function (item) {
      this.echartsCurveR2C2MenuListActive = item;
    },
    // 获取设备在线率数据
    onGetDeviceOnlineData: function () {
      var that = this;
      var data = {
        seType: 1,
        projectId: this.currentProject.id,
      };
      this.$axiosAdmin({
        method: "post",
        url: "api/screen/specialEquipment/particularStatisticsByPidAndSeType",
        data: JSON.stringify(data),
      })
        .then((res) => {
          if (res.code === 0) {
            that.deviceOnlineData = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取司机认证信息数据
    onGetDeviceDriverData: function () {
      var that = this;
      var data = {
        seType: 1,
        projectId: this.currentProject.id,
      };
      this.$axiosAdmin({
        method: "post",
        url:
          "api/screen/specialEquipment/particularStatisticsDriverByPidAndSeType",
        data: JSON.stringify(data),
      })
        .then((res) => {
          if (res.code === 0) {
            that.deviceDriverData = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取种类报警信息
    onGetdeviceAlarmTypeData: function () {
      var that = this;
      var data = {
        seType: 1,
        projectId: this.currentProject.id,
        timeType: this.echartsCurveR1C3MenuListActive.value
      };
      this.$axiosAdmin({
        method: "post",
        url:
          "api/screen/specialEquipment/particularStatisticsAlarmCateByPidAndSeType",
        data: JSON.stringify(data),
      })
        .then((res) => {
          // if (res.code === 0) {
            that.deviceAlarmTypeData = res.data;
            that.echartsCraneR1C3Init(); // 绘制种类报警信息饼图
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取设备载重数据
    onGetDeviceCurveData: function () {
      var that = this;
      var data = {
        seType: 1,
        projectId: this.currentProject.id,
        timeType: this.echartsCurveR2C1MenuListActive.value
      };
      this.$axiosAdmin({
        method: "post",
        url: "api/screen/specialEquipment/particularCurveByPidAndSeType",
        data: JSON.stringify(data),
      })
        .then((res) => {
          if (res.code === 0) {
            that.deviceCurveData = res.data;
            that.echartsCraneR2C1Init(); // 绘制设备载重数据曲线图
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取设备报警信息
    onGetDeviceAlarmTotalData: function () {
      var that = this;
      var data = {
        seType: 1,
        projectId: this.currentProject.id,
        timeType: this.echartsCurveR2C2MenuListActive.value
      };
      this.$axiosAdmin({
        method: "post",
        url:
          "api/screen/specialEquipment/particularStatisticsAlarmTotalByPidAndSeType",
        data: JSON.stringify(data),
      })
        .then((res) => {
          if (res.code === 0) {
            that.deviceAlarmTotalData = res.data;
            that.echartsCraneR2C2Init(); // 绘制设备报警次数饼图
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
@Width: 192rem;
.row-height-1,
.row-height-2 {
  height: 335 / @Width;
}
.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.crane {
  width: 100%;
  height: 100%;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20 / @Width;
  }
  .r1-c1 {
    width: 660 / @Width;
    height: 410 / @Width;
  }
  .r1-c2 {
    width: 460 / @Width;
    height: 410 / @Width;
  }
  .r1-c3 {
    width: 660 / @Width;
    height: 410 / @Width;
  }
  .r2-c1 {
    width: 1150 / @Width;
    height: 410 / @Width;
  }
  .r2-c2 {
    width: 660 / @Width;
    height: 410 / @Width;
  }
  .border-wrap {
    .flex {
      display: flex;
    }
    .grow {
      flex-grow: 1;
    }
    .border-top {
      .border-top-left,
      .border-top-right {
        width: 50 / @Width;
        height: 50 / @Width;
        img {
          width: 50 / @Width;
          height: 50 / @Width;
        }
      }
      .border-top {
        height: 50 / @Width;
        img {
          width: 100%;
          height: 50 / @Width;
        }
      }
      .border-title {
        width: 205 / @Width;
        height: 50 / @Width;
        background-image: url("../assets/border/border-title.png");
        filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
        -moz-background-size: 100% 100%;
        background-size: 100% 100%;
        .border-name {
          color: #00e4ff;
          font-size: 18 / @Width;
          font-weight: 700;
          text-align: center;
          margin-top: 8 / @Width;
        }
      }
    }

    .border-body {
      .border-left,
      .border-right {
        width: 25 / @Width;
        img {
          width: 25 / @Width;
          height: 100%;
        }
      }
      .border-body-middle {
        background-image: url("../assets/border/border-body-bg.png");
        filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
        -moz-background-size: 100% 100%;
        background-size: 100% 100%;
      }
    }

    .border-bottom {
      .border-bottom-left,
      .border-bottom-right {
        width: 25 / @Width;
        height: 25 / @Width;
        img {
          width: 25 / @Width;
          height: 25 / @Width;
        }
      }
      .border-bottom {
        height: 25 / @Width;
        img {
          width: 100%;
          height: 25 / @Width;
        }
      }
      .border-bottom-middle {
        width: 258 / @Width;
        height: 25 / @Width;
        img {
          width: 258 / @Width;
          height: 25 / @Width;
        }
      }
    }
  }
}

.r1-c1-content-wrap {
  // background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .r1-c1-content-row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .r1-c1-content-row1-item {
      width: 174 / @Width;
      height: 151 / @Width;
      background-image: url("../assets/device/device-online.png");
      filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 22 / @Width;
      .num {
        color: #ffbe22;
        font-size: 26 / @Width;
        font-weight: 700;
      }
    }
  }
  .r1-c1-content-row2 {
    font-size: 24 / @Width;
    color: #ffbe22;
    margin-top: 20 / @Width;
    margin-bottom: 20 / @Width;
  }
  .r1-c1-content-row3 {
    height: 40 / @Width;
    display: flex;
    .max {
      flex-grow: 1;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 20 / @Width;
      overflow: hidden;
      .val {
        height: 40 / @Width;
        background-color: #ffbe22;
        border-radius: 20 / @Width;
      }
    }
    .percent {
      margin-left: 10 / @Width;
      font-size: 24 / @Width;
      line-height: 40 / @Width;
      color: #ffbe22;
    }
  }
}
.r1-c2-content-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 14 / @Width;
  color: #00e4ff;
  .dirver-list-wrap {
    width: 410 / @Width;
    height: 280 / @Width;
    flex-grow: 1;
    border-top: 1px solid rgba(0, 121, 253, 0.5);
    border-bottom: 1px solid rgba(0, 121, 253, 0.5);
    box-sizing: border-box;

    display: flex;
    flex-direction: column;

    .dirver-list-title {
      display: flex;
      justify-content: space-between;
      background: linear-gradient(
        rgba(26, 62, 184, 0.8),
        rgba(26, 62, 184, 0.1)
      );
      .dirver-list-title-item {
        width: 33%;
        text-align: center;
        height: 50 / @Width;
        line-height: 50 / @Width;
      }
    }
    .driver-list-data {
      overflow: scroll;
    }
    .dirver-list-row {
      display: flex;
      justify-content: space-between;
      .dirver-list-row-item {
        width: 33%;
        text-align: center;
        height: 50 / @Width;
        line-height: 50 / @Width;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .dirver-list-row-item-icon {
        width: 30 / @Width;
        height: 30 / @Width;
      }
    }
  }
}
#echarts-crane-r1-c3,
#echarts-crane-r2-c1,
#echarts-crane-r2-c2 {
  // background-color: #fff;
  width: 100%;
  height: 100%;
}

.echarts-crane-r1-c3-wrap,
.echarts-crane-r2-c1-wrap,
.echarts-crane-r2-c2-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  .menu {
    position: absolute;
    top: -30 / @Width;
    display: flex;
    .item {
      color: #00e4ff;
      height: 16 / @Width;
      line-height: 16 / @Width;
      font-size: 16 / @Width;
      padding: 0 10 / @Width;
      border-right: 1 / @Width solid #00e4ff;
    }
    .item:first-child {
      border-left: 0;
    }
    .item:last-child {
      border-right: 0;
    }
    .item-active {
      color: #fff;
    }
  }
}
</style>
